import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileResource {
  public constructor(private httpClient: HttpClient) {}

  public getUserProfile(): Observable<any> {
    return this.httpClient.get('api/auth0/userprofile');
  }

  public getRoles(): Observable<any> {
    return this.httpClient.get('api/auth0/roles');
  }
}
