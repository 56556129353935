import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentResource {
  public constructor(private httpClient: HttpClient) {}

  public getInfo(): Observable<any> {
    return this.httpClient.get('api/actuator/info');
  }
}
