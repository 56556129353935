<mat-toolbar data-test="header" color="primary" [ngStyle]="style">
  <div class="app-title">
    <span>Masterdata Manager</span>
  </div>
  <div class="app-environment" *ngIf="environment">
    <span> {{ environment }}</span>
  </div>
  <div>
    <div class="header__user" *ngIf="!username">
      Anoniem
      <button class="header__logout" (click)="handleLogin()" mat-stroked-button>Inloggen</button>
    </div>
  </div>
  <div class="header__user" *ngIf="username">
    Welkom {{username}}
    <button class="header__logout" (click)="handleLogout()" mat-stroked-button>Uitloggen</button>
  </div>
</mat-toolbar>
