import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ctgb-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input()
  public title: string = '';

  constructor(private matDialogRef: MatDialogRef<any>) {}

  public cancel(): void {
    this.matDialogRef.close(false);
  }

  public accept(): void {
    this.matDialogRef.close(true);
  }
}
