import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProfileResource } from '../../resources/profile.resource';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class ProfileService {
  public userprofile: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isMasterdataEditor: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPublisher: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public roles: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  public constructor(private profileResouce: ProfileResource, private authService: AuthService) {
    authService.isAuthenticated$.subscribe(authenticated => {
      if (authenticated) {
        this.isAuthenticated.next(true);
        profileResouce.getUserProfile().subscribe(profile => {
          this.userprofile.next(profile);
        });
        profileResouce.getRoles().subscribe((roles: string[]) => {
          if (roles.indexOf('mst-admin') !== -1) {
            this.isAdmin.next(true);
            this.isMasterdataEditor.next(true);
            this.isPublisher.next(true);
            this.roles.next(['mst-admin', 'mst-publisher', 'mst-masterdata-editor']);
          }
          if (roles.indexOf('mst-masterdata-editor') !== -1) {
            this.isMasterdataEditor.next(true);
            this.roles.next(roles);
          }
          if (roles.indexOf('mst-publisher') !== -1) {
            this.isPublisher.next(true);
            this.roles.next(roles);
          }
        });
      }
    });
  }
}
