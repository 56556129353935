import { Component, Inject, OnDestroy } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { ProfileService } from '../../services/auth/profile.service';
import { Subscription } from 'rxjs';
import { EnvironmentResource } from '../../resources/environment.resource';

@Component({
  selector: 'ctgb-app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.scss'],
})
export class AppBarComponent implements OnDestroy {
  public username: string = '';
  public environment?: string;
  public style = {};

  public subscriptions: Subscription[] = [];

  public constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private profileService: ProfileService,
    private environmentResource: EnvironmentResource
  ) {
    this.subscriptions.push(
      profileService.userprofile.subscribe(userprofile => {
        this.username = userprofile?.name;
      }),
      environmentResource.getInfo().subscribe(info => {
        this.setEnvironment(info?.app?.environment?.toLowerCase());
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public handleLogout(): void {
    this.authService.logout({ 
      logoutParams: { returnTo: this.document.location.origin }
    });
  }

  public handleLogin(): void {
    this.authService.loginWithRedirect();
  }

  private setEnvironment(environment: string): void {
    switch (environment.toLowerCase()) {
      case environment.toLowerCase().match(/ˆ*nt/)?.input:
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.style = { 'background-color': 'blue' };
        this.environment = 'Ontwikkel-omgeving';
        break;
      case environment.toLowerCase().match(/ˆ*st/)?.input:
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.style = { 'background-color': 'orange' };
        this.environment = 'Test-omgeving';
        break;
      case environment.toLowerCase().match(/ˆ*ac/)?.input:
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.style = { 'background-color': 'green' };
        this.environment = 'Acceptatie-omgeving';
        break;
      case environment.toLowerCase().match(/ˆ*pr/)?.input:
        this.style = {};
        this.environment = undefined;
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/naming-convention
        this.style = { 'background-color': 'red' };
        this.environment = 'Omgeving: ' + environment;
    }
  }
}
