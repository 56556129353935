<div class="confirm-dialog">
  <div class="confirm-dialog__header">
    <h3>{{title}}</h3>
    <button mat-button (click)="cancel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="confirm-dialog__content">
    <div class="confirm-dialog__actions">
      <button (click)="accept()" mat-raised-button color="primary">Ja</button>
      <button (click)="cancel()" mat-raised-button color="warn">Nee</button>
    </div>
  </div>
</div>
