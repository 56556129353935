<div class="dashboard-container">
  <h2>Welkom bij de Masterdata Manager van het CTGB</h2>
  <p>Dit systeem is bedoeld om alle masterdata lijsten binnen het CTGB op 1 plek samen te brengen.</p>
  <p>Daarnaast zijn ook een aantal MST Rapportages toegevoegd dit voorheen in SpagoBI te vinden waren.</p>
  <p>De masterdata lijsten zijn opgedeeld in een groepen:</p>
  <ul>
    <li>Algemene lijsten die voor Gewas en Biociden gelden</li>
    <li>Lijsten voor Gewas of Biociden</li>
    <li>Keuzelijstjes t.b.v. de "Aanvraagtypes"</li>
    <li>Statische lijsten</li>
  </ul>
  <h2>Handige links</h2>
  <ul>
    <li><a href="/api/swagger-ui.html" target="_blank">Link naar de API documentatie</a></li>
  </ul>
</div>
