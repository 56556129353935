import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export class Environment {
  public environment: string = '';
}

export class FeatureSwitches {
  public static FEATURE_DTG_ENABLED = 'featureDTGEnabled';
  public features: Record<string, boolean> = {
    featureDTGEnabled: false,
  };

  public isFeatureEnabled(key: string): boolean {
    return this.features[key] || false;
  }
}

@Injectable()
export class FeatureSwitchService {
  public featureSwitches: BehaviorSubject<FeatureSwitches>;
  private switches = new FeatureSwitches();

  constructor(private httpClient: HttpClient) {
    this.featureSwitches = new BehaviorSubject<FeatureSwitches>(this.switches);
    this.httpClient.get<Environment>('/environment').subscribe((env: Environment) => {
      this.switches.features[FeatureSwitches.FEATURE_DTG_ENABLED] = (env as Record<string, any>)[FeatureSwitches.FEATURE_DTG_ENABLED];
      this.featureSwitches.next(this.switches);
    });
  }
}
