import { Component, Input } from '@angular/core';

@Component({
  selector: 'ctgb-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input() public label: string = '';
  @Input() public icon: string = '';
  @Input() public color: string | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' = 'textPrimary';
}
