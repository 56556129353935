<div class="navigation" color="secondary">
  <button mat-button (click)="navigate('/')">Home</button>
  <ng-container *ngFor="let section of sections">
    <button mat-button [matMenuTriggerFor]="belowMenu" *ngIf="section.isEnabled && section.isAllowed">{{ section.title }}</button>
    <mat-menu #belowMenu="matMenu" yPosition="below">
      <ng-container *ngFor="let item of section.items">
        <button mat-menu-item *ngIf="section.isEnabled && section.isAllowed" (click)="navigate(item.route)">{{ item.label }}</button>
      </ng-container>
    </mat-menu>
  </ng-container>
</div>
