import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(module => module.DashboardModule),
    // Protect a route by registering the auth guard in the `canActivate` hook
    // canActivate: [AuthGuard],
  },
  {
    path: 'versions',
    loadChildren: () => import('./pages/dtg-versions/dtg-versions.module').then(module => module.DtgVersionsModule),
    // Protect a route by registering the auth guard in the `canActivate` hook
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
  },
  {
    path: 'masterdata',
    loadChildren: () => import('./pages/masterdata/masterdata.module').then(module => module.MasterdataModule),
    // Protect a route by registering the auth guard in the `canActivate` hook
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then(module => module.ReportsModule),
    // Protect a route by registering the auth guard in the `canActivate` hook
    // canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
  },
  { path: '**', redirectTo: 'versions', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
