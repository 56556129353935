<div class="diff-container__header">
  <h3>{{this.eventType}} {{this.itemId}}</h3>
    <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="diff-container__content">
  <div class="diff-container__diff" [innerHtml]="outputHtml"></div>
  <span class="diff-container__actions">
    <button mat-raised-button color="primary" *ngIf="(profile.isAdmin | async) === true" (click)="push()" data-test="push_button">
      Push
    </button>
    <button mat-raised-button color="primary" (click)="close()" data-test="close_button">Sluiten</button>
  </span>
</div>
