import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as jsonStableStringify from "json-stable-stringify";
import * as Diff from "diff";
import * as Diff2Html from "diff2html";
import { Pair } from "../../model/masterdata-model";
import {ProfileService} from "../../services/auth/profile.service";

@Component({
  selector: 'ctgb-view',
  templateUrl: './diff.component.html',
  styleUrls: ['./diff.component.scss'],
})
export class DiffComponent {

  public outputHtml: any;
  public itemId: number;
  public eventType: string;
  public diffData: Pair<any, any>[]
  private pair: Pair<any, any> | undefined

  constructor(
    public profile: ProfileService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: { itemId: number; eventType: string, diffData: Pair<any, any>[] }
  ) {
    this.itemId = data.itemId
    this.eventType = data.eventType
    this.diffData = data.diffData
    this.generateDiff(this.itemId)
  }

  public push(): void {
    this.dialogRef.close({ push: true, pair: this.pair });
  }

  public close(): void {
    this.dialogRef.close({ push: false });
  }

  public generateDiff(itemId: number) {
    this.pair = this.diffData.find(value => value.first.id === itemId);
    if (!this.pair) return

    const first = {...this.pair.first}
    const second = {...this.pair.second}
    first.id = second.id

    const newText = this.pair.first ? jsonStableStringify(first, {space: '  '}) : '';
    const oldText = this.pair.second ? jsonStableStringify(second, {space: '  '}) : '';
    const diff = Diff.createTwoFilesPatch(`Item ${second?.id}`, `Item ${first?.id}`, oldText, newText);
    this.outputHtml = Diff2Html.html(diff, {drawFileList: false, outputFormat: 'side-by-side', matching: 'lines'});
  }
}
